import React from 'react';
import {
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import { Header } from './common/components/Header';
import Register from './views/register';
import Login from './views/login';
import ForgotPassword from './views/forgotPassword';
import ConfirmResetPassword from './views/confirmResetPassword';
import VerifyEmail from './views/verifyEmail';
import UpdateProfile from './views/updateProfile';
import ConfirmOTP from './views/confirmOTP';
import MigrateExistingUser from './views/migrateExistingUser';
import MigrationFailed from './views/migrationFailed';
import UserDuplicated from './views/userDuplicated';
import VerifySsn from './views/verifySsn';
import GovIdTypeSelection from './views/govIdTypeSelection';
import GovIdResponse from './views/govIdResponse';


const HeaderWithRouter = withRouter((props) => <Header {...props} />);
const RegisterWithRouter = withRouter((props) => <Register {...props} />);
const ConfirmOTPWithRouter = withRouter((props) => <ConfirmOTP {...props} />);
const LoginWithRouter = withRouter((props) => <Login {...props} />);
const GovIdResponseWithRouter = withRouter((props) => <GovIdResponse {...props} />);
const routes= (
  <div className="container">
    <HeaderWithRouter />
    <div className="container__content">
      <Switch>
        <Route exact path="/register" component={RegisterWithRouter} />
        <Route exact path="/login" component={LoginWithRouter} />
        <Route exact path="/reset-password" component={ForgotPassword} />
        <Route exact path="/confirm-reset-password" component={ConfirmResetPassword} />
        <Route exact path="/verify-email" component={VerifyEmail} />
        <Route exact path="/update-profile" component={UpdateProfile} />
        <Route exact path="/gov-id-response" component={GovIdResponseWithRouter} />
        <Route exact path="/user-duplicated" component={UserDuplicated} />
        <Route exact path="/idv/otp" component={ConfirmOTPWithRouter} />
        <Route exact path="/idv/migrate" component={MigrateExistingUser} />
        <Route exact path="/idv/ssn4" component={VerifySsn} />
        <Route exact path="/idv/failed" component={MigrationFailed} />
        <Route exact path="/idv/select-gov-id-type" component={GovIdTypeSelection} />
        <Route path="*" component={RegisterWithRouter} />
      </Switch>
    </div>
  </div>
);

export default routes;
