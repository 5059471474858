/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-len */
import React, {
  Component,
  Fragment,
} from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import { Field } from 'redux-form';
import classNames from 'classnames';
import {
  states,
  suffixes,
} from '../../common/lib/generateStates';
import RenderField from '../../common/components/Form/RenderField';
import Button from '../../common/components/Button';
import {
  email,
  phone,
  normalizePhone,
  normalizeZip,
  normalizeDob,
  required,
  state,
  zipcode,
  validatePassword,
  validateDob,
  isEmpty,
  requiredCheckbox,
  noSpecialChars,
  normalizeName,
  normalizeSsn4,
  ssn4,
  getAlphabeticOrder,
  cityMaxLength,
  ssn4IsEntered,
  noSsn4IsChecked,
} from '../../common/lib/validation';

import { getError } from '../../common/lib/getError';
import { CITY_HELP_TEXT } from '../../common/lib/constants';

require('../../../style/index.css');
require('./register.css');

const termsAndConditionsComponent = () => <Fragment>I accept the <a href="https://nylottery.ny.gov/legal/privacy-policy" target="_blank">Terms and Conditions</a>*</Fragment>;
class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ssn4Value: '',
      noSsn4Value: false,
    };
    this.confirmPassword = this.confirmPassword.bind(this);
    this.confirmNoSsn4IsChecked = this.confirmNoSsn4IsChecked.bind(this);
    this.confirmSsn4IsEntered = this.confirmSsn4IsEntered.bind(this);
  }

  confirmPassword(value) {
    const { password } = this.props;
    if (!isEmpty(value) && value !== password) {
      return 'Passwords must match';
    } else if (isEmpty(value)) {
      return 'Required';
    }
    return null;
  }

  confirmNoSsn4IsChecked(value) {
    noSsn4IsChecked(value, this.props, this, this.state);
  }

  confirmSsn4IsEntered(value) {
    ssn4IsEntered(value, this.props, this, this.state);
  }

  render() {
    const {
      submitFormFn,
      registrationFailed,
      formSubmissionError,
      errorDetail,
      statusCode,
      isFetching,
    } = this.props;

    return (
      <Fragment>
        <form
          className="needs-validation"
          noValidate
        >
          <div className="personal-info-wrap">
            <h3 className="form-header">Personal Information</h3>
            <Field
              name="firstName"
              type="text"
              component={RenderField}
              label="Legal First Name*"
              validate={required}
              normalize={normalizeName}
            />
            <Field
              name="middleName"
              type="text"
              component={RenderField}
              label="Middle Name"
              normalize={normalizeName}
            />
            <Field
              name="lastName"
              type="text"
              component={RenderField}
              label="Last Name*"
              validate={required}
              normalize={normalizeName}
            />
            <Field
              name="suffix"
              type="select"
              options={suffixes}
              component={RenderField}
              label="Suffix"
              defaultSelectValue="Select Suffix"
            />
            <Field
              name="streetNumber"
              type="text"
              component={RenderField}
              label="House/Building Number*"
              validate={required}
              placeholder="Ex: 123"
              normalize={noSpecialChars}
            />
            <Field
              name="street"
              type="text"
              component={RenderField}
              label="Street Name*"
              validate={required}
              placeholder="Ex: Main Street"
            />
            <Field
              name="addressLine2"
              type="text"
              component={RenderField}
              label="Apartment/Floor/Other"
              placeholder="Ex: Apartment 2, Floor 3, etc (Optional)"
            />
            <Field
              name="city"
              type="text"
              component={RenderField}
              label="City/Town*"
              validate={[cityMaxLength, required]}
              helpText={CITY_HELP_TEXT}
            />
            <Field
              name="state"
              type="select"
              component={RenderField}
              label="State*"
              options={getAlphabeticOrder(states)}
              validate={state}
              defaultSelectValue="Select State"
            />
            <Field
              name="zip"
              type="text"
              component={RenderField}
              label="Zip Code*"
              validate={zipcode}
              normalize={normalizeZip}
            />
            <Field
              name="phone"
              type="tel"
              component={RenderField}
              label="Phone Number*"
              placeholder="(XXX) XXX-XXXX"
              validate={phone}
              normalize={normalizePhone}
              prepend="phone"
              className="has-prepend"
              inputmode="tel"
            />
            <Field
              name="ssn4"
              type="tel"
              component={RenderField}
              label="Social Security Number*"
              placeholder="**** (last 4 digits)"
              normalize={normalizeSsn4}
              inputmode="tel"
              validate={this.state.noSsn4Value ? [this.confirmSsn4IsEntered] : [this.confirmSsn4IsEntered, ssn4]}
            />
            <Field
              name="noSsn4"
              type="checkbox"
              component={RenderField}
              label="I do not have a Social Security Number; or I do not wish to provide my Social Security Number. (You will be asked to verify your identity on the following page. Please note that SSN4 is not stored to your account and only used for the initial ID Verification)"
              className="hanging-checkbox small"
              validate={this.confirmNoSsn4IsChecked}
            />
            <Field
              name="birthdate"
              type="tel"
              component={RenderField}
              label="Date of Birth*"
              normalize={normalizeDob}
              placeholder="MM/DD/YYYY"
              inputmode="tel"
              validate={validateDob}
            />
            <Field
              name="isOver18"
              type="checkbox"
              component={RenderField}
              label="I certify that I'm at least 18 years old*"
              className="hanging-checkbox"
              validate={requiredCheckbox}
            />
          </div>
          <div className="personal-info-wrap">
            <h3 className="form-header">Account Setup</h3>
            <Field
              name="email"
              type="text"
              component={RenderField}
              label="Email Address*"
              placeholder="This will be your username"
              autocorrect="off"
              autocapitalize="none"
              validate={email}
              prepend="mail"
              className="has-prepend"
              fieldId="sso-email"
            />
            <Field
              name="password"
              type="password"
              component={RenderField}
              label="Password*"
              validate={validatePassword}
              helpText="Your password must be 8–20 characters long, contain letters and numbers, and must not contain spaces, or emoji."
            />
            <Field
              name="confirmPassword"
              type="password"
              component={RenderField}
              label="Confirm Password*"
              validate={this.confirmPassword}
            />
            <Field
              name="acceptedTermsAndConditions"
              type="checkbox"
              component={RenderField}
              label={termsAndConditionsComponent()}
              validate={requiredCheckbox}
            />
            <Field
              className="checkbox-spacing"
              name="collectnwin"
              type="checkbox"
              component={RenderField}
              label="Sign me up for the Collect 'N Win updates"
            />
            <Field
              className={classNames('last-checkbox', { 'last-checkbox--error': registrationFailed || formSubmissionError })}
              name="newsletter"
              type="checkbox"
              component={RenderField}
              label="Sign me up for the New York Lottery Newsletter"
            />

            {registrationFailed && (
              <p dangerouslySetInnerHTML={{ __html: getError(errorDetail, statusCode) }} />
            )}
            {formSubmissionError && (
              <p className="submit-error">Please see required fields above to complete registration.</p>
            )}

            <div className="button-wrap">
              {isFetching ?
                <div className="loading-wrapper">
                  <BounceLoader
                    color="#084267"
                  />
                </div>
                :
                <Button
                  label="Register"
                  onClickFn={submitFormFn}
                  className={classNames('nyl-button', { 'button-error': registrationFailed || formSubmissionError })}
                />
              }
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default RegisterForm;
